/* eslint-disable no-nested-ternary */

export const CONSUMER_KEY_SECRET: string =
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	process.env.MOZART_WOOCOMMERCE_CONSUMER_KEY_SECRET!;

export const SHOP_URL =
	process.env.NEXT_PUBLIC_MOZART_SHOP_URL ?? "https://mozart-bett.de";
export const WC_API_URL = `${SHOP_URL}/wp-json/wc/v3`;

export const PAGE_URL =
	process.env.VERCEL_ENV === "production"
		? "https://mozart-zendesk-app.vercel.app"
		: process.env.VERCEL_URL
			? `https://${process.env.VERCEL_URL}`
			: "http://localhost:3000";
