"use client";

import { useFormStatus } from "react-dom";

export default function FormLoader() {
	const { pending } = useFormStatus();

	if (!pending) {
		return null;
	}

	return (
		<div className="fixed bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-primary/75">
			<div className="h-12 w-12 animate-spin rounded-full border-4 border-border border-t-accent" />
		</div>
	);
}
