"use client";

import { useState } from "react";

import Form from "@/components/form";
import Modal, { type Result } from "@/components/modal";

import type { OrderData } from "@/types/order-data";
import type { Product } from "@/types/product";
import type { ShippingMethod } from "@/types/shipping-method";

import "./page.css";

interface ClientProps {
	products: Product[];
	shippingMethods: ShippingMethod[];
	orderData: OrderData;
}

export default function Client({
	products,
	shippingMethods,
	orderData,
}: ClientProps) {
	const [showModal, setShowModal] = useState<Result>(false);

	return (
		<>
			<Form
				setShowModal={setShowModal}
				products={products}
				shippingMethods={shippingMethods}
				orderData={orderData}
			/>

			{showModal && (
				<Modal closeModal={() => setShowModal(false)} data={showModal} />
			)}
		</>
	);
}
