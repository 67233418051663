const formatter = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
});

export function formatPrice(price: string | number) {
	if (typeof price === "string") {
		// biome-ignore lint/style/noParameterAssign: <explanation>
		price = Number.parseFloat(price.replace(",", "."));
	}

	return formatter.format(price as number);
}
