import { SHOP_URL } from "@/utils/constants";

export type Result =
	| false
	| {
			orderId?: number;
			error?: string;
	  };

interface ModalProps {
	closeModal: () => void;
	data: {
		error?: string;
		orderId?: number;
	};
}

export default function Modal({
	closeModal,
	data: { orderId, error },
}: ModalProps) {
	const orderLink = `${SHOP_URL}/wp-admin/post.php?post=${orderId}&action=edit`;

	return (
		<div id="successModal" className="modal">
			<div className="modal-content">
				<button
					type="button"
					className="close"
					onClick={() => {
						closeModal();
					}}
				>
					&times;
				</button>
				{error && <p>{error}</p>}
				{orderId && (
					<p>
						Die Bestellung wurde erfolgreich erstellt!{" "}
						<a href={orderLink} id="orderLink" target="_blank" rel="noreferrer">
							Bestellung #{orderId} ansehen
						</a>
					</p>
				)}
			</div>
		</div>
	);
}
