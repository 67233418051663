import { useMemo } from "react";
import type { ChangeEvent, ReactNode, SelectHTMLAttributes } from "react";

interface InputProps {
	label: string;
	name: string;
	value: SelectHTMLAttributes<HTMLSelectElement>["value"];
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
	options: { label: ReactNode; value: string | number }[];
	customValidity?: string;
	required?: boolean;
	emptyText?: string;
}

export default function Select({
	label,
	name,
	value,
	onChange,
	customValidity,
	options,
	emptyText = "Bitte wähle eine Option aus.",
}: InputProps) {
	const selectOptions = useMemo(() => {
		const selectOptions = [];

		for (const { label, value } of options) {
			selectOptions.push(
				<option key={value} value={value}>
					{label}
				</option>,
			);
		}

		return selectOptions;
	}, [options]);

	function handleChange(event: ChangeEvent<HTMLSelectElement>) {
		event.target.setCustomValidity("");
		onChange(event);
	}

	return (
		<div className="mb-4">
			<label
				className="mb-2 block text-sm font-medium text-secondary"
				htmlFor={name}
			>
				{label}
			</label>
			<select
				className="block h-12 w-full rounded-md border bg-secondary px-4 text-sm outline-none transition-colors focus:border-accent"
				name={name}
				id={name}
				onChange={handleChange}
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
				value={value || ""}
				onInvalid={(event) => {
					if (customValidity)
						(event.target as HTMLSelectElement).setCustomValidity(
							customValidity,
						);
				}}
			>
				{emptyText && (
					<option value="" disabled>
						{emptyText}
					</option>
				)}
				{selectOptions}
			</select>
		</div>
	);
}
