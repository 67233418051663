"use client";

import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";

interface ContextData {
	loading: boolean;
}

interface ContextState {
	data: ContextData;
	setData: Dispatch<SetStateAction<ContextData>>;
}

const Context = createContext<ContextState>({} as ContextState);

export function useStore() {
	return useContext(Context);
}

export default function StoreContext({ children }: { children: ReactNode }) {
	const [data, setData] = useState<ContextData>({
		loading: false,
	});
	const value = useMemo(() => ({ data, setData }), [data]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
}
