"use client";

import { useState } from "react";

import { useStore } from "@/store/store";
import type { OrderProduct, Product } from "@/types/product";

import Input from "./input";
import Select from "./select";

interface ProductsProps {
	products: Product[];
	addProduct(product: OrderProduct): void;
}

export default function Products({ addProduct, products }: ProductsProps) {
	const [variations, setVariations] = useState<Product[]>([]);

	const [orderProduct, setProduct] = useState<OrderProduct>({
		productId: 0,
		variationId: 0,
		quantity: "1",
		price: "0",
		comments: "",
	} as OrderProduct);

	const { data, setData } = useStore();

	return (
		<div>
			<Select
				name="productId"
				label="Produkt auswählen"
				value={orderProduct.productId}
				onChange={async (event) => {
					setData((prev) => ({ ...prev, loading: true }));

					const productId = Number(event.target.value);

					setVariations([]);
					setProduct((product) => ({ ...product, productId, variationId: 0 }));

					try {
						const response = await fetch(
							`/api/fetch-variations?productId=${productId}`,
						);

						if (!response.ok) {
							throw new Error("Network response was not ok");
						}

						const nextVariations: Product[] = await response.json();

						if (nextVariations.length > 0) {
							setVariations(nextVariations);
						}
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error(error);
					} finally {
						setData((prev) => ({ ...prev, loading: false }));
					}
				}}
				options={products.map((product) => ({
					value: product.id,
					label: product.name,
				}))}
				customValidity="Bitte wähle ein Produkt aus."
				required
				emptyText="-- Produkt wählen --"
			/>

			{variations.length > 0 && (
				<Select
					name="variationId"
					label="Variante auswählen"
					value={orderProduct.variationId}
					onChange={(event) => {
						const variationId = Number(event.target.value);
						setProduct((product) => ({ ...product, variationId }));
					}}
					options={variations.map((variation) => {
						const label =
							variation.attributes.length === 0
								? `Variation ${variation.id}`
								: variation.attributes
										.map((attr) => `${attr.name}: ${attr.option}`)
										.join(", ");
						return {
							value: variation.id,
							label,
						};
					})}
					emptyText="-- Variante wählen --"
				/>
			)}

			<div className="grid grid-cols-2 gap-2">
				<Input
					name="quantity"
					label="Anzahl"
					value={orderProduct.quantity}
					placeholder="1"
					onChange={(event) => {
						setProduct((product) => ({
							...product,
							quantity: event.target.value,
						}));
					}}
				/>
				<Input
					name="price"
					label="Gesamtbetrag"
					value={orderProduct.price}
					placeholder="0,00"
					onChange={(event) => {
						setProduct((product) => ({
							...product,
							price: event.target.value,
						}));
					}}
				/>
				<div className="mb-4 col-span-2">
					<label
						htmlFor="comment"
						className="mb-2 block text-sm font-medium text-secondary"
					>
						Produktanmerkung (für Fey Bestellung)
					</label>
					<textarea
						id="comment"
						name="comment"
						className="bg-secondary w-full mb-4 border rounded-md outline-none transition-colors focus:border-accent px-4 py-2 min-h-8 max-h-64"
						value={orderProduct.comments}
						onChange={(event) =>
							setProduct((product) => ({
								...product,
								comments: event.target.value,
							}))
						}
					/>
				</div>
			</div>

			<button
				type="button"
				className="mb-4 block h-12 w-full cursor-pointer rounded-md border-2 border-accent font-bold text-accent transition-colors hover:bg-accent/90 hover:text-white disabled:cursor-not-allowed disabled:border-border disabled:text-secondary disabled:hover:bg-primary"
				disabled={
					data.loading ||
					orderProduct.productId === 0 ||
					(variations.length > 0 && orderProduct.variationId === 0)
				}
				onClick={() => {
					const product = products.find(
						(product) => orderProduct.productId === product.id,
					);

					if (product) {
						orderProduct.name = product.name;

						if (orderProduct.variationId) {
							const variation = variations.find(
								(variation) => variation.id === orderProduct.variationId,
							);

							if (variation) {
								orderProduct.attributes = variation.attributes
									.map((attribute) => attribute.option)
									.join(", ");
							}
						}
					}
					addProduct(orderProduct);
				}}
			>
				Produkt hinzufügen
			</button>
		</div>
	);
}
