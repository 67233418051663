import type { ChangeEvent, InputHTMLAttributes } from "react";

interface InputProps {
	label: string;
	name: string;
	value: InputHTMLAttributes<HTMLInputElement>["value"];
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	type?: InputHTMLAttributes<HTMLInputElement>["type"];
	customValidity?: string;
	placeholder?: string;
	required?: boolean;
}

export default function Input({
	label,
	name,
	type = "text",
	value,
	onChange,
	customValidity,
	placeholder,
}: InputProps) {
	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		event.target.setCustomValidity("");
		onChange(event);
	}

	return (
		<div className="mb-4">
			<label
				className="mb-2 block text-sm font-medium text-secondary"
				htmlFor={name}
			>
				{label}
			</label>
			<input
				className="block h-12 w-full rounded-md border bg-secondary px-4 text-sm outline-none transition-colors focus:border-accent"
				type={type}
				name={name}
				id={name}
				onChange={handleChange}
				value={value}
				placeholder={placeholder}
				onInvalid={(event) => {
					if (customValidity)
						(event.target as HTMLInputElement).setCustomValidity(
							customValidity,
						);
				}}
			/>
		</div>
	);
}
