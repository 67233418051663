/* eslint-disable import/named */
import { useEffect, useState } from "react";
import { useFormStatus } from "react-dom";

import Input from "./input";
import type { Result } from "./modal";
import Products from "./products";
import Select from "./select";

import { createOrder } from "@/app/actions";

import { useStore } from "@/store/store";

import type { OrderData } from "@/types/order-data";
import type { OrderProduct, Product } from "@/types/product";
import type { ShippingMethod } from "@/types/shipping-method";

import { formatPrice } from "@/utils/price";
import FormLoader from "./loader";

declare const ZAFClient: {
	init():
		| undefined
		| {
				get(key: string): Promise<Record<string, string>>;
		  };
};

export interface Fields {
	orderId: string;
	orderStatus: string;
	customerNote: string;
	shippingMethodId: string;
	orderType: string;
}

interface FormProps {
	setShowModal(data: Result): void;
	products: Product[];
	shippingMethods: ShippingMethod[];
	orderData: OrderData;
}

export default function Form({
	products,
	shippingMethods,
	orderData,
	setShowModal,
}: FormProps) {
	const { data } = useStore();

	const [fields, setFields] = useState<Fields>({
		orderId: "",
		orderStatus: "",
		customerNote: "",
		shippingMethodId: "",
		orderType: "",
	});

	const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([]);

	function addOrderProduct(product: OrderProduct) {
		setOrderProducts((prev) => [...prev, product]);
	}

	useEffect(() => {
		async function initClient() {
			const client = ZAFClient.init();

			if (client) {
				await client
					.get("ticket.customField:custom_field_8023614753949")
					.then((data: Record<string, string>) => {
						const orderId =
							data["ticket.customField:custom_field_8023614753949"];

						if (orderId) {
							setFields((fields) => ({ ...fields, orderId }));
						}
					});
			}
		}

		void initClient();
	}, []);

	return (
		<form
			action={async (formData: FormData) => {
				const shippingMethod = shippingMethods.find(
					(method) => method.instance_id === Number(fields.shippingMethodId),
				);

				if (shippingMethod) {
					const result = await createOrder(
						formData,
						orderProducts,
						shippingMethod,
					);

					if (!result.success) {
						setShowModal({
							error: `Fehler beim Erstellen der Bestellung: ${result.error}`,
						});
						return;
					}
					setShowModal({ orderId: result.orderId });
				}
			}}
			className="text-sm"
		>
			<FormLoader />

			<Input
				name="orderId"
				label="Bestellnummer"
				value={fields.orderId}
				placeholder="Bestellnummer eingeben"
				customValidity="Bitte gebe die Bestellnummer ein."
				onChange={(event) => {
					setFields((fields) => ({
						...fields,
						[event.target.name]: event.target.value,
					}));
				}}
				required
			/>

			<hr className="my-8" />

			<Products products={products} addProduct={addOrderProduct} />

			<hr className="my-8" />

			{orderProducts.length > 0 ? (
				orderProducts.map((product, i) => (
					<div
						key={`${product.productId}-${product.variationId}-${i}`}
						className="grid min-h-12 grid-cols-[1fr,24px] items-center gap-4 rounded-md border bg-secondary px-4 py-2"
					>
						<div className="grid grid-rows-2 text-secondary">
							<b
								title={product.name}
								className="w-full truncate font-bold text-primary"
							>
								{product.quantity}x {product.name}
							</b>
							{product.comments && <p>{product.comments}</p>}
							<div>
								{product.attributes && (
									<span className="block">{product.attributes}</span>
								)}
								<span className="block">{formatPrice(product.price)}</span>
							</div>
						</div>
						<button
							type="button"
							className="ml-auto flex h-6 w-6 basis-6 cursor-pointer items-center justify-center rounded-md text-red-400 transition-colors hover:bg-tertiary"
							onClick={() => {
								setOrderProducts((prev) =>
									prev.filter((_, index) => index !== i),
								);
							}}
						>
							<svg
								width="16"
								height="16"
								viewBox="0 0 24 24"
								className="fill-red-400"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Trash</title>
								<path d="M5.8133 18.1996L6.81109 18.133L5.8133 18.1996ZM18.1867 18.1996L19.1845 18.2661L18.1867 18.1996ZM3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7V5ZM21 7C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5V7ZM11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11H11ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16H9ZM15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11H15ZM13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16H13ZM14.9056 6.24926C15.0432 6.78411 15.5884 7.1061 16.1233 6.96844C16.6581 6.83078 16.9801 6.28559 16.8424 5.75074L14.9056 6.24926ZM4.00221 6.06652L4.81552 18.2661L6.81109 18.133L5.99779 5.93348L4.00221 6.06652ZM8.80666 22H15.1933V20H8.80666V22ZM19.1845 18.2661L19.9978 6.06652L18.0022 5.93348L17.1889 18.133L19.1845 18.2661ZM19 5H5V7H19V5ZM3 7H5V5H3V7ZM19 7H21V5H19V7ZM15.1933 22C17.2992 22 19.0444 20.3673 19.1845 18.2661L17.1889 18.133C17.1189 19.1836 16.2463 20 15.1933 20V22ZM4.81552 18.2661C4.9556 20.3673 6.7008 22 8.80666 22V20C7.75373 20 6.88113 19.1836 6.81109 18.133L4.81552 18.2661ZM9 11V16H11V11H9ZM13 11V16H15V11H13ZM12 4C13.3965 4 14.5725 4.95512 14.9056 6.24926L16.8424 5.75074C16.2874 3.59442 14.3312 2 12 2V4ZM9.09447 6.24926C9.42756 4.95512 10.6035 4 12 4V2C9.66885 2 7.7126 3.59442 7.1576 5.75074L9.09447 6.24926Z" />
							</svg>
						</button>
					</div>
				))
			) : (
				<p className="text-center">Keine Produkte</p>
			)}

			<hr className="my-8" />

			<div className="grid grid-cols-2 gap-2">
				<Select
					label="Bestellstatus"
					name="orderStatus"
					required
					value={fields.orderStatus}
					onChange={(event) => {
						setFields((fields) => ({
							...fields,
							[event.target.name]: event.target.value,
						}));
					}}
					customValidity="Bitte wähle einen Bestellstatus."
					options={[
						{ value: "on-hold", label: "In Wartestellung" },
						{ value: "processing", label: "In Bearbeitung" },
						{ value: "transferred", label: "Übermittelt" },
					]}
					emptyText="-- Bestellstatus wählen --"
				/>

				<Select
					label="Bestellart"
					name="orderType"
					required
					value={fields.orderType}
					onChange={(event) => {
						setFields((fields) => ({
							...fields,
							[event.target.name]: event.target.value,
						}));
					}}
					customValidity="Bitte wähle eine Bestellart."
					options={orderData.orderTypeOptions}
					emptyText="-- Bestellart wählen --"
				/>
			</div>

			<Input
				name="customerNote"
				label="Bestellanmerkung"
				value={fields.customerNote}
				placeholder="Anmerkung eingeben"
				onChange={(event) => {
					setFields((fields) => ({
						...fields,
						[event.target.name]: event.target.value,
					}));
				}}
			/>

			<Select
				name="shippingMethod"
				label="Versandmethode"
				required
				value={fields.shippingMethodId}
				onChange={(event) => {
					setFields((fields) => ({
						...fields,
						shippingMethodId: event.target.value,
					}));
				}}
				customValidity="Bitte wähle eine Versandmethode."
				options={shippingMethods.map((method) => ({
					value: method.instance_id,
					label: method.title,
				}))}
				emptyText="-- Versandmethode wählen --"
			/>

			<hr className="my-8" />

			<SubmitButton
				disabled={
					data.loading ||
					orderProducts.length === 0 ||
					!fields.orderId ||
					!fields.orderStatus ||
					!fields.shippingMethodId ||
					!fields.orderType
				}
			/>
		</form>
	);
}

interface SubmitButtonProps {
	disabled?: boolean;
}
function SubmitButton({ disabled = false }: SubmitButtonProps) {
	const { pending } = useFormStatus();

	return (
		<button
			className="h-12 w-full rounded-md bg-accent font-bold text-white transition hover:brightness-110 disabled:bg-tertiary disabled:text-secondary disabled:cursor-not-allowed"
			type="submit"
			disabled={disabled || pending}
		>
			Support Order erstellen
		</button>
	);
}
